import React from 'react';
import { withTranslation } from 'react-i18next';

import '../styles/style.scss';
import {Hero, Footer, Pricing,
//References, Reference,
FeatureTestimonial, Signup, 
//GetStarted, 
FeaturedIn, VideoSection, Logo, Logot,
//Partner, FeaturedIn, 
BlogSection} from '../components';
import {WebLayout} from '../layouts';
import {graphql} from "gatsby"; 

const IndexPage = ({t, i18n, data}) => {
  return (
    <WebLayout
      title="Duuers - SaaS startup making online proposal software"
      meta={[
        {name: 'description', content: "Duuers is a SaaS startup that makes online proposal software for small businesses. We're from Helsinki and have offices in Toronto too!"},
        {name: 'keywords', content: ''},
      ]}
      link={[{
          rel: "alternate", href: "https://www.duuers.com/fi/", hreflang: "fi"
      },{
          rel: 'alternate', href: 'https://www.duuers.com/', hreflang: 'en'
      },{
          rel: 'alternate', href: 'https://www.duuers.com/', hreflang: 'x-default'
      }]}
    >
      <Hero>
        <h1 className={"font_second semibold font40"}>{t('index.header')}</h1>
        <p className="font20 padding_top20 black opacity9 text">
          {t('index.text')}
        </p>
        <Signup/>
      </Hero>
      
  {/*    <section className="content_4 bg_white padding_bottom25">
      <div className="container nopadding max_width970 text-center">
        <h2 className="top50 font_second light font42">Duuers.com solution is now part of the Louhi Networks.</h2>
        <div className="max_width770 margin_auto top45 font22 light text">
          Louhi Networks has acquired the rights to Duuers.com proposal management tool and will develop the solution onwards together with its customers.
          <br></br>
          <br></br>
          
        </div>
      </div>
    </section>
*/}
      { 
/* 
// Commented the Reference slide details
     <References>
  			<Reference  name="Iiro" title="B2B sales pro" image="/images/references/iiro_500x500.png">
          Our proposals look SO much better than they used to. We're never switching back!
  			</Reference>
  			<Reference  name="Tuukka" title="Accountant" image="/images/references/tuukka_500x500.png">
          Creating proposals used to be the hardest thing about my work. Now it's one of the easiest!
        </Reference>
        <Reference name="Marko" title="B2B 'sales engineer'" image="/images/references/marko_500x500.png">
          I can track each and every stage of the proposals I send, which means I close a lot more deals. Thanks Duuers! 
        </Reference>
  			<Reference  name="Mikko" title="Salesman and entrepreneur" image="/images/references/mikko_500x500.png">
          This has saved us loads of time. I recommend Duuers to all the salespeople I know!  			
        </Reference>
  			<Reference name="Reeta" title="Designer" image="/images/references/reeta_laamo_500x500.png">
          I can edit proposals I've already sent, and everything updates automatically for my customers. Awesome tool!
        </Reference>
  			<Reference name="Jani" title="Recruitment and staffing" image="/images/references/jax_500x500.png">
          I love that proposals are so quick to make, easy to send and then follow up on.
        </Reference>
        <Reference  name="Ari" title="Entrepreneur in the energy sector" image="/images/references/ari_500x500.png">
          Duuers focuses on what really matters. This makes it the easiest, most effective tool I've tried for sending out proposals.  			
        </Reference>
        <Reference  name="Henri" title="Small business owner" image="/images/references/henri_500x500.png">
          With Duuers it's quick and easy to make great looking proposals. Better still, they're clear and easy to understand for my customers. 
        </Reference>
      </References>
            */
}
<section className={`content_34 padding_top50 padding_bottom0 bg_light_gray`}>
        <div className="container padding_bottom0  text-center">
        {/* <h2 className="font42 light font_second dark_gray">Our customers</h2> */}
          <h2 className="font42 light font_second dark_gray">{t('component.ourcustomers.title')}</h2>
          <Logot>
            <Logo 
            image1="/images/asiakkaat_logot/epu_coat_logo.png"
            image2="/images/asiakkaat_logot/tyomaapavelut_logo.jpg"
            />
            <Logo 
            image1="/images/asiakkaat_logot/poukama_logo.jpg"
            image2="/images/asiakkaat_logot/Prosessipuhdistus-Logo.png"
            />
            <Logo 
            image1="/images/asiakkaat_logot/blommendahl_logo.jpg"
            image2="/images/asiakkaat_logot/digispace_logo.png"
            />
            <Logo 
            image1="/images/asiakkaat_logot/Evigo_logo.png"
            image2="/images/asiakkaat_logot/fslog.webp"
            />
            <Logo 
            image1="/images/asiakkaat_logot/Harjulan_palvelut_logo.png"
            image2="/images/asiakkaat_logot/hawkhill_logo.png"
            />
            <Logo 
            image1="/images/asiakkaat_logot/helpot_kotisivut_brand_logo.svg"
            image2="/images/asiakkaat_logot/KopterCam-White.svg"
            />
            <Logo 
            image1="/images/asiakkaat_logot/mainosrakentajat_logo.png"
            image2="/images/asiakkaat_logot/mainostoimisto_luma_logo.png"
            />
            <Logo 
            image1="/images/asiakkaat_logot/Nostick_logo.png"
            image2="/images/asiakkaat_logot/photocom_logo.png"
            />
            <Logo 
            image1="/images/asiakkaat_logot/Raaka_Media_logo.png"
            image2="/images/asiakkaat_logot/sisustuskorjaamo_logo.svg"
            />
            <Logo 
            image1="/images/asiakkaat_logot/SisustusMinnaK_logo.png"
            image2="/images/asiakkaat_logot/solves_logo.svg"
            />
            <Logo 
            image1="/images/asiakkaat_logot/stooribysara_logo.png"
            image2="/images/asiakkaat_logot/succee-logo.svg"
            />
            <Logo 
            image1="/images/asiakkaat_logot/tavux_logo.png"
            image2="/images/asiakkaat_logot/thebimcrowd_logo.png"
            />
            <Logo 
            image1="/images/asiakkaat_logot/villageworks_logo.jpg"
            image2="/images/asiakkaat_logot/3Economix_logo.png"
            />
          </Logot>
      
        </div> 
      </section>
      
      
     {/* <GetStarted/> */}
      <div id="features">
      <FeatureTestimonial
            sectionid="faster"
            title={t('component.featureTestimonial.faster.title')}
            description={t('component.featureTestimonial.faster.description')}
            srcSet="/images/features/better-sales@2x_.png 1x, /images/features/better-sales@2x_.png 2x"
            src="/images/features/proposal-creation@2x.png"
            //linkkiteksti="Lue mitä mieltä asiakkamme ovat Duuersista. →"
            //linkkiurl="https://www.duuers.com/fi/referenssitarinat"
            linkkiteksti={t('component.featureTestimonial.faster.linkkiteksti')}
            //linkkiurl={t('component.featureTestimonial.faster.linkkiurl')}
            //linkkiurl="#"
          />
          <FeatureTestimonial
            sectionid="professional"
            title={t('component.featureTestimonial.professional.title')}
            description={t('component.featureTestimonial.professional.description')}
            srcSet="/images/features/proposal-creation@2x.png 1x, /images/features/proposal-creation@2x.png 2x"
            src="/images/features/proposal-creation@2x.png"
            //linkkiteksti="Katso miltä digitaalinen tarjouksesi voi näyttää asiakaalle. →"
            linkkiurl="https://app.duuers.com/preview-recipient-view/en"
            linkkiteksti={t('component.featureTestimonial.professional.linkkiteksti')}
            //linkkiurl={t('component.featureTestimonial.professional.linkkiurl')}
            invert={true}
            target="_blank"
          />
          <FeatureTestimonial
            sectionid="data"
            title={t('component.featureTestimonial.data.title')}
            description={t('component.featureTestimonial.data.description')}
            //srcSet="/images/features/proposal-creation@2x.png 1x, /images/features/proposal-creation@2x.png 2x"
            //src="/images/features/proposal-creation@2x.png"
            srcSet="/images/features/teamwork-fi.png 1x, /images/features/teamwork-fi@2x.png 2x, /images/features/teamwork-fi@3x.png 3x" 
            src="/images/features/teamwork-fi.png"
            //linkkiteksti="Duuersilla on helppo seurata tarjouksen tilaa. →"
            //linkkiurl="https://www.duuers.com/fi/blog/tarjouksen-tekeminen/seuraa-tarjouksen-etenemista"
            linkkiteksti={t('component.featureTestimonial.data.linkkiteksti')}
            //linkkiurl={t('component.featureTestimonial.data.linkkiurl')}
            //linkkiurl="#"
            //invert={true}
            
          />
          <FeatureTestimonial
            sectionid="quality"
            title={t('component.featureTestimonial.quality.title')}
            description={t('component.featureTestimonial.quality.description')}
            //srcSet="/images/features/proposal-creation@2x.png 1x, /images/features/proposal-creation@2x.png 2x"
            //src="/images/features/proposal-creation@2x.png"
            srcSet="/images/features/editor-fi.png 1x, /images/features/editor-fi@2x.png 2x, /images/features/editor-fi@3x.png 3x"
            src="/images/features/editor-fi.png"
            //linkkiteksti="Lue miksi laadukas tarjous on menestystekijä. →"
            //linkkiurl="https://www.duuers.com/fi/blog/yrittajyys/erotu-tarjoustyolla-kilpailijoista"
            //linkkiteksti={t('component.featureTestimonial.quality.linkkiteksti')}
            linkkiurl={t('component.featureTestimonial.quality.linkkiurl')}
            //linkkiurl="#"
            invert={true}
          />
     {/*   <FeatureTestimonial
          title={t('component.featureTestimonial.faster.title')}
          description={t('component.featureTestimonial.faster.description')}
          srcSet="/images/features/proposal-creation@2x.png 1x, /images/features/proposal-creation@2x.png 2x"
          src="/images/features/proposal-creation@2x.png" */}
        {  /*video={{
            title: "Heikki's story",
            description: 'From 2 hours to 10 minutes!',
            srcSet:"/images/references/heikki_500x500.png 1x, /images/references/heikki_500x500.png 2x",
            src:"/images/references/heikki_500x500.png",
            href:"https://www.youtube.com/embed/rxFIbiCqGis?autoplay=1"
          }}*/ }
      {/*    /> */}
       {/* <FeatureTestimonial
          title={t('component.featureTestimonial.sales.title')}
          description={t('component.featureTestimonial.sales.description')}
          srcSet="/images/features/better-sales@2x_.png 1x, /images/features/better-sales@2x_.png 2x"
          src="/images/features/better-sales@2x_.png"*/}
       {  /* video={{
            title: "Jani's story",
            description: 'Sales has never been so simple',
            srcSet:"/images/references/jani_500x500.png 1x, /images/references/jani_500x500.png 2x",
            src:"/images/references/jani_500x500.png",
            href:"https://www.youtube.com/embed/qbdCiD-Xufo?autoplay=1"
          }}*/}
      {/*    invert={true}
          /> */}
      {/*  <FeatureTestimonial
          title={t('component.featureTestimonial.customization.title')}
          description={t('component.featureTestimonial.customization.description')}
          srcSet="/images/features/customization@2x_.png 1x, /images/features/customization@2x.png 2x"
          src="/images/features/customization@2x.png" */}
        {  /*video={{
            title: "Sami and Ville's story",
            description: 'Duuers changed how we think about making proposals',
            srcSet:"/images/references/jax2_500x500.png 1x, /images/references/jax2_500x500.png 2x",
            src:"/images/references/jax2_500x500.png",
            href:"https://www.youtube.com/embed/J0IGPkx1_No?autoplay=1"
          }}*/}
        {/*  />*/}
      </div>      {
      /*
      <FeaturedIn logos={[{
        srcSet: '/images/featured/ukkopro.png 1x, /images/featured/ukkopro.png 2x',
        src: '/images/featured/ukkopro.png'
      },
      {
        srcSet: '/images/featured/holvi.png 1x, /images/featured/holvi.png 2x',
        src: '/images/featured/holvi.png'
      },
      {
        srcSet: '/images/featured/zeckit.png 1x, /images/featured/zeckit.png 2x',
        src: '/images/featured/zeckit.png'
      },{
        srcSet: '/images/featured/vastuu.png 1x, /images/featured/vastuu.png 2x',
        src: '/images/featured/vastuu.png'
      },{
        srcSet: '/images/featured/yrittajat.png 1x, /images/featured/yrittajat.png 2x',
        src: '/images/featured/yrittajat.png'
      }]}/>*/}
      <Pricing currency="EUR"/>
      <section className={`feature_26 padding_top95 bg_light_blue padding_bottom100`}>
      	<div className="container nopadding">
      		<div className="row justify-content-between justify-content-xl-start">
      			<div className={`col-lg-6`}>
      				<img srcSet={null} src="/images/proposal_template_.png" className="width_full bg" alt="" />	
      			</div>
      			<div className={`col-lg-5 offset-1`}>
      				<div className="max_width600 inner">
      			{/*	<h1 className="font_second light font42">Miltä tarjous näyttää asiakkaallesi?</h1> */}
      					<h1 className="font_second light font42">{t('component.proposalmodel.title')}</h1>
      			{/*	<div className="top30 font18 light dark_blue hyphens text bottom30">Katso rekisteröitymättä miltä mallitarjouksemme näyttää asiakkaasi näkökulmasta!</div> */}
      			{/*	<div className="top30 font18 light dark_blue hyphens text bottom30">{t('component.proposalmodel.subtitle')}</div>
      					<div className="bottom20 item flex" style={{flexWrap: 'nowrap'}}><i className="fa fa-check blue right50"></i><span>Ammattimaisen näköinen tarjous, jolla erotut joukosta.</span></div>
      					<div className="bottom20 item flex" style={{flexWrap: 'nowrap'}}><i className="fa fa-check blue right50"></i><span>Yrityksesi brändi ja toimiala esille, logon ja taustakuvan avulla.</span></div>
      					<div className="bottom20 item flex" style={{flexWrap: 'nowrap'}}><i className="fa fa-check blue right50"></i><span>Asiakas voi kommentoida tarjousta ja voit vastata hänelle heti.</span></div>
      					<div className="bottom20 item flex" style={{flexWrap: 'nowrap'}}><i className="fa fa-check blue right50"></i><span>Tarjouksen hyväksyminen nappia painamalla.</span></div> */}
      					<div className="top30 font18 light dark_blue hyphens text bottom30">{t('component.proposalmodel.subtitle')}</div>
      					<div className="bottom20 item flex" style={{flexWrap: 'nowrap'}}><i className="fa fa-check blue right50"></i><span>{t('component.proposalmodel.list.1')}</span></div>
      					<div className="bottom20 item flex" style={{flexWrap: 'nowrap'}}><i className="fa fa-check blue right50"></i><span>{t('component.proposalmodel.list.2')}</span></div>
      					<div className="bottom20 item flex" style={{flexWrap: 'nowrap'}}><i className="fa fa-check blue right50"></i><span>{t('component.proposalmodel.list.3')}</span></div>
      					<div className="bottom20 item flex" style={{flexWrap: 'nowrap'}}><i className="fa fa-check blue right50"></i><span>{t('component.proposalmodel.list.4')}</span></div>
			        {/*  <a rel="noopener noreferrer" target="_blank" href="https://app.duuers.com/preview-recipient-view/fi" className="btn blue size60 padding_sides35 radius6 top30 semibold width_full"><span className="white">Tutustu asiakkaan näkymää</span></a> */}
			          <a rel="noopener noreferrer" target="_blank" href={t('component.proposalmodel.button.url')} className="btn blue size60 padding_sides35 radius6 top30 semibold width_full"><span className="white">{t('component.proposalmodel.button.text')}</span></a>
      				</div>
      			</div>
      		</div>
      	</div>
      </section>
      <FeatureTestimonial
            sectionid="starttoday"
            title={t('component.featureTestimonial.starttoday.title')}
            description={t('component.featureTestimonial.starttoday.description')}
            srcSet="/images/features/customization@2x_.png 1x, /images/features/customization@2x_.png 2x"
            src="/images/features/customization@2x_.png"
            //linkkiteksti="Lue miten luot hyvän tarjouspohjan →"
            //linkkiurl="https://www.duuers.com/fi/blog/tarjouksen-tekeminen/10x-nopeammat_tarjoukset"
            linkkiteksti={t('component.featureTestimonial.starttoday.linkkiteksti')}
            //linkkiurl={t('component.featureTestimonial.starttoday.linkkiurl')}
            //linkkiurl="#"
            invert={true}
            />
      <FeatureTestimonial
            sectionid="choose"
            title={t('component.featureTestimonial.choose.title')}
            description={t('component.featureTestimonial.choose.description')}
            phone={t('component.featureTestimonial.choose.phone')}
            //srcSet="/images/features/customization@2x_.png 1x, /images/features/customization@2x_.png 2x"
            //src="/images/features/customization@2x_.png"
            srcSet="/images/features/branding-fi_.png 1x, /images/features/branding-fi@2x_.png 2x, /images/features/branding-fi@3x_.png 3x"
            src="/images/features/branding-fi_.png"
            googlelomake="https://forms.gle/UCPL7gutvwXs8Jti6"
            osoite="Upseerinkatu 1, 02600 Espoo"
            //googlelomake="https://forms.gle/XH3M9iT5UfcRpLMU8"
            //linkkiteksti="asiakaspalvelu@duuers.com →"
            //linkkiurl="mailto:asiakaspalvelu@duuers.com"
            linkkiteksti={t('component.featureTestimonial.choose.linkkiteksti')}
            linkkiurl={t('component.featureTestimonial.choose.linkkiurl')}
            buttontext="Contact us"
           // invert={true}
            />
      <section className={`content_34 padding_top60 padding_bottom60 video-section`}>
        <div className="container padding_bottom20  text-center">
          <h2 className="font42 light font_second dark_gray">{t('component.getStarted.title')}</h2>
          <div className="margin_auto top25 max_width770 font18 light text">{t('component.getStarted.description')}</div>
        </div>
        <VideoSection src="https://www.youtube.com/embed/r3ZWo4RpNMM?autoplay=1" poster="/images/hero_video-poster.png" bg="white"/>
      </section>
      <BlogSection posts={data.allMarkdownRemark.edges} bg='light_blue'/>
      <section id="contactus" className={`content_34 padding_top60 padding_bottom60 phone-number`}>
        <div className='container nopadding'>  
          <h2 className='font_second light font40 dark_gray text-center'>{t('section.heretohelp.text')}</h2>
         {/* <div className="text-center top30"><span>Upseerinkatu 1, 02600 Espoo</span></div>
          <div className="text-center top30"><a className="blue" href="mailto:asiakaspalvelu@duuers.com">asiakaspalvelu@duuers.com →</a></div> */}
          <p className='text-center font18 padding_top20'><i className="fa fa-phone blue"></i>&nbsp;{t('section.heretohelp.phone')}</p>
         {/* <div className="top30 text-center"><a rel="noopener noreferrer" target="_blank" href="https://forms.gle/UCPL7gutvwXs8Jti6" className="btn blue size60 padding_sides35 radius6 top30 semibold"><span className="white">Contact us</span></a></div> */}
        </div>
      </section>
      <Footer/>
    </WebLayout>
);
};


export default withTranslation()(IndexPage);

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: {fields: [frontmatter___date], order: DESC}, 
      filter: {fields: {language: {eq: "en"}}, frontmatter: { date: { ne: null } } }
      ) {
      edges {
        node {
          excerpt
          fields {
            slug
            path
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            meta {
              name
              content
            }
            link {
              rel
              href
              hreflang
            }
            hero
          }
        }
      }
    }
  }
`